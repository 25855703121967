@tailwind components;
@tailwind utilities;

@import './normalize.css';

* {
  --primary: #2d3c95;
  --background: #ffffff;
  --background-dim: #f3f3f3;
  --background-dim-light: #f9f9f9;
  --primary-foreground: #ffffff;
  --foreground: #161616;
  --base-unit: 8px;
}

svg[display='none'] {
  display: none;
}